import React from "react";
import Logo from "../../assets/logo.svg";

export default function PrivacyPolicy() {
    return (
        <>
            <div className="bg-white">
                {/* Barra de navegação */}
                <header className="absolute inset-x-0 top-0 z-50">
                    <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                        <div className="flex lg:flex-1">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Verbeux</span>
                                <img className="h-8 w-auto" src={Logo} alt="logo" />
                            </a>
                        </div>
                        <div className="hidden lg:flex lg:gap-x-12">
                            <a href="/" className="text-sm font-semibold leading-6 text-gray-900">Home</a>
                        </div>
                        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                            <a href="https://admin.verbeux.com.br"
                               className="rounded-md bg-purple-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">
                                Acessar &rarr;
                            </a>
                        </div>
                    </nav>
                </header>

                {/* Conteúdo principal */}
                <main className="relative isolate px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:py-32">
                        <div className="text-center">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Política de Privacidade</h1>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Sua privacidade é importante para nós. Leia atentamente a nossa Política de Privacidade.
                            </p>
                        </div>

                        <div className="mt-16 space-y-8 text-gray-700">
                            {/* 1. Introdução */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">1. Introdução</h2>
                                <p className="mt-4 leading-7">
                                    Bem-vindo à Verbeux! Esta Política de Privacidade descreve como coletamos, usamos, armazenamos e protegemos suas informações ao utilizar nossa plataforma. Ao acessar ou usar a Verbeux, você concorda com os termos aqui descritos.
                                </p>
                            </section>

                            {/* 2. Informações Coletadas */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">2. Informações Coletadas</h2>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">2.1 Informações Fornecidas por Você</h3>
                                <p className="mt-2 leading-7">
                                    Coletamos informações fornecidas diretamente, como dados de cadastro (nome, email, empresa) e informações relacionadas aos assistentes criados.
                                </p>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">2.2 Informações Geradas pelo Uso</h3>
                                <p className="mt-2 leading-7">
                                    Capturamos dados como registros de acesso, interações, desempenho da plataforma e logs de mensagens processadas pelos assistentes.
                                </p>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">2.3 Integração com Google Agenda</h3>
                                <p className="mt-2 leading-7">
                                    Os dados coletados dessa integração incluem eventos criados, editados ou excluídos, sempre respeitando os limites autorizados pelo usuário.
                                </p>
                                <p>
                                  *Nota: Nunca utilizamos as informações da sua agenda para outros fins que não sejam os definidos pela integração com os assistentes.
                                </p>
                            </section>

                            {/* 3. Finalidades do Uso de Dados */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">3. Finalidades do Uso de Dados</h2>
                                <p className="mt-4 leading-7">
                                    Utilizamos seus dados para personalizar a experiência, integrar serviços de terceiros e melhorar nossas funcionalidades.
                                </p>
                            </section>

                            {/* 4. Compartilhamento de Informações */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">4. Compartilhamento de Informações</h2>
                                <p className="mt-4 leading-7">
                                    Suas informações não são compartilhadas com terceiros, exceto em casos de autorização explícita, exigências legais ou suporte técnico.
                                </p>
                            </section>

                            {/* 5. Segurança de Dados */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">5. Segurança de Dados</h2>
                                <p className="mt-4 leading-7">
                                  A segurança das suas informações é essencial para nós. Adotamos medidas rigorosas para proteger seus dados contra acesso não autorizado, uso indevido, alteração ou exclusão. Isso inclui:
                                  Uso de criptografia em trânsito e em repouso;
                                  Adoção de políticas de acesso restrito para dados sensíveis;
                                  Monitoramento contínuo e auditorias regulares para garantir a conformidade com os padrões de segurança;
                                  No entanto, é importante reconhecer que nenhuma solução digital é completamente isenta de riscos. Incentivamos você a proteger suas credenciais de acesso e notificar-nos imediatamente em caso de suspeita de atividade não autorizada;
                                </p>
                            </section>

                            {/* 6. Integração com o Google Agenda */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">6. Integração com o Google Agenda</h2>
                                <p className="mt-4 leading-7">
                                  A Verbeux permite que você conecte sua conta do Google Agenda para melhorar as funcionalidades dos assistentes virtuais. Para isso, utilizamos o protocolo OAuth, garantindo que suas credenciais não sejam armazenadas pela Verbeux. As permissões concedidas incluem:
                                  Acesso aos eventos existentes na agenda: Para consultar dados;
                                  Criação de eventos: Para agendar compromissos diretamente pela interação com os assistentes;
                                  Edição de eventos: Para atualizar detalhes conforme necessário;
                                  Exclusão de eventos: Para remover compromissos;
                                </p>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">6.1 Uso Limitado</h3>
                                <p className="mt-2 leading-7">
                                  As informações acessadas da sua agenda são utilizadas exclusivamente para cumprir as funcionalidades integradas da plataforma e não são armazenadas além do necessário para sua execução.                                
                                </p>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">6.2 Revogação de Permissões</h3>
                                <p className="mt-2 leading-7">
                                  Você pode revogar a integração com o Google Agenda a qualquer momento, acessando as configurações de segurança da sua conta Google ou pela plataforma verbeux
                                </p>
                            </section>

                            {/* 7. Seus Direitos */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">7. Seus Direitos</h2>
                                <p className="mt-4 leading-7">
                                    Você pode solicitar acesso, correção ou exclusão de seus dados pessoais.
                                </p>
                            </section>

                            {/* 8. Alterações */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">8. Alterações na Política</h2>
                                <p className="mt-4 leading-7">
                                    Podemos atualizar esta Política de Privacidade periodicamente. Recomendamos revisá-la regularmente.
                                </p>
                            </section>

                            {/* 9. Contato */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">9. Contato</h2>
                                <p className="mt-4 leading-7">
                                    Caso tenha dúvidas, entre em contato em:
                                    <a href="mailto:verbeux@verbeux.com.br" className="text-purple-600 underline"> verbeux@verbeux.com.br</a>.
                                </p>
                            </section>

                            {/* 10. Legislação */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">10. Legislação Aplicável</h2>
                                <p className="mt-4 leading-7">
                                    Esta política é regida pelas leis do Brasil. Qualquer disputa será resolvida no foro de Fortaleza, Ceará. Data de Atualização: Novembro de 2024.
                                </p>
                            </section>
                        </div>
                    </div>
                </main>

                {/* Rodapé */}
                <footer className="bg-white">
                    <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                        <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
                        <div className="sm:flex sm:items-center sm:justify-between">
                            <span className="text-sm text-gray-500 sm:text-center">© 2024 Verbeux™. Todos os direitos reservados.</span>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}
