import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/home";
import Index from "./pages/CustomerService";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";

export default function Router(){
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/'} index element={<Index/>} />
                <Route path={'/customer-service'} element={<Index/>}/>
                <Route path={'/terms'} element={<Terms />}/>
                <Route path={'/privacy-policy'} element={<PrivacyPolicy />}/>
            </Routes>
        </BrowserRouter>
    )
}
