import React from "react";
import Logo from "../../assets/logo.svg";

export default function Terms() {
    return (
        <>
            <div className="bg-white">
                {/* Barra de navegação */}
                <header className="absolute inset-x-0 top-0 z-50">
                    <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                        <div className="flex lg:flex-1">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Verbeux</span>
                                <img className="h-8 w-auto" src={Logo} alt="logo" />
                            </a>
                        </div>
                        <div className="hidden lg:flex lg:gap-x-12">
                            <a href="/" className="text-sm font-semibold leading-6 text-gray-900">Home</a>
                        </div>
                        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                            <a href="https://admin.verbeux.com.br"
                               className="rounded-md bg-purple-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">
                                Acessar &rarr;
                            </a>
                        </div>
                    </nav>
                </header>

                {/* Conteúdo principal */}
                <main className="relative isolate px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl py-16 sm:py-24 lg:py-32">
                        <div className="text-center">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Termos de Uso</h1>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Leia atentamente os termos e condições antes de utilizar nossa plataforma.
                            </p>
                        </div>

                        <div className="mt-16 space-y-8 text-gray-700">
                            {/* 1. Introdução */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">1. Introdução</h2>
                                <p className="mt-4 leading-7">
                                    Bem-vindo à plataforma Verbeux! Estes Termos de Uso descrevem as regras e regulamentos para o uso da plataforma Verbeux, um serviço desenvolvido para criar e gerenciar assistentes inteligentes personalizados. Ao acessar ou utilizar a Verbeux, você concorda em estar vinculado a estes Termos de Uso. Caso você não concorde com algum item, por favor, não utilize a nossa plataforma.
                                </p>
                            </section>

                            {/* 2. Sobre a Verbeux */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">2. Sobre a Verbeux</h2>
                                <p className="mt-4 leading-7">
                                    A Verbeux é uma solução completa de criação de assistentes virtuais inteligentes que auxilia empresas e indivíduos na comunicação eficiente com seus clientes. A plataforma permite que você crie, personalize e gerencie assistentes virtuais de maneira intuitiva, integrando funcionalidades como automações, personalização de fluxos de conversa e análise de interações.
                                </p>
                                <p className="mt-4 leading-7">
                                    Além disso, a Verbeux fornece integração com o Google Agenda, permitindo aos usuários acessar, criar, editar e excluir eventos diretamente de sua agenda. Essa funcionalidade é projetada para facilitar a organização e melhorar a produtividade.
                                </p>
                            </section>

                            {/* 3. Cadastro e Responsabilidade do Usuário */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">3. Cadastro e Responsabilidade do Usuário</h2>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">3.1 Requisitos para Cadastro</h3>
                                <p className="mt-2 leading-7">
                                    - Para utilizar a Verbeux, você deve criar uma conta fornecendo informações precisas, atualizadas e completas.<br />
                                    - Você é responsável por manter suas credenciais de acesso seguras. A Verbeux não se responsabiliza por atividades realizadas na sua conta por terceiros.
                                </p>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">3.2 Responsabilidades</h3>
                                <p className="mt-2 leading-7">
                                    - Você se compromete a utilizar a plataforma de acordo com todas as leis aplicáveis e com respeito aos Termos de Uso.<br />
                                    - É proibido utilizar a Verbeux para fins ilícitos, abusivos, fraudulentos ou que prejudiquem a experiência de outros usuários.
                                </p>
                            </section>

                            {/* 4. Funcionalidades da Plataforma */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">4. Funcionalidades da Plataforma</h2>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">4.1 Criação de Assistentes Virtuais</h3>
                                <p className="mt-2 leading-7">
                                    A Verbeux permite criar assistentes virtuais com personalização avançada. Os usuários podem configurar fluxos de conversa, integrar assistentes gerados por inteligência artificial e utilizar linguagem natural para configurar respostas e ações.
                                </p>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">4.2 Integração com Google Agenda</h3>
                                <p className="mt-2 leading-7">
                                    - Acessar sua agenda para consultar eventos.<br />
                                    - Criar novos eventos com base em interações automatizadas com os assistentes.<br />
                                    - Editar eventos existentes de maneira prática e intuitiva.<br />
                                    - Excluir eventos, quando necessário.
                                </p>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">4.3 Análise de Dados</h3>
                                <p className="mt-2 leading-7">
                                  A plataforma oferece insights detalhados sobre interações, permitindo que os usuários monitorem a performance de seus assistentes. Esses dados incluem métricas como:
                                  - Volume de mensagens.
                                  - Taxa de resolução de problemas.
                                  - Tempo médio de resposta.
                                </p>
                                <p className="mt-4 leading-7">
                                     **Nota:** O uso da integração com o Google Agenda requer autorização explícita do usuário. Você pode revogar essa autorização a qualquer momento nas configurações da sua conta.
                                </p>
                            </section>

                            {/* 5. Propriedade Intelectual */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">5. Propriedade Intelectual</h2>
                                <p className="mt-4 leading-7">
                                    Todo o conteúdo e código-fonte da plataforma Verbeux são protegidos por leis de propriedade intelectual. É estritamente proibida a cópia, reprodução ou redistribuição de qualquer parte da plataforma sem autorização prévia por escrito.
                                </p>
                            </section>

                            {/* 6. Uso de Dados e Privacidade */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">6. Uso de Dados e Privacidade</h2>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">6.1 Coleta de Dados</h3>
                                <p className="mt-2 leading-7">
                                    - Ao utilizar a Verbeux, coletamos informações fornecidas por você, como dados de cadastro e interações com os assistentes.<br />
                                    - Também coletamos informações relacionadas à integração com serviços de terceiros, como o Google Agenda.
                                </p>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">6.2 Uso de Dados</h3>
                                <p className="mt-2 leading-7">
                                    Os dados coletados são utilizados para melhorar a experiência do usuário e fornecer funcionalidades personalizadas.
                                </p>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">6.3 Proteção de Dados</h3>
                                <p className="mt-2 leading-7">
                                    A Verbeux emprega tecnologias modernas para proteger os dados armazenados na plataforma.
                                </p>
                            </section>

                            {/* 7. Integração com o Google Agenda */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">7. Integração com o Google Agenda</h2>
                                <p className="mt-4 leading-7">
                                    A autorização para acesso ao Google Agenda é feita com base no protocolo OAuth, garantindo segurança nas credenciais.
                                </p>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">7.1 Autorização</h3>
                                <p className="mt-2 leading-7">
                                  Para utilizar as funcionalidades do Google Agenda, você deve autorizar a Verbeux a acessar sua conta Google.
                                </p>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">7.2 Permissões</h3>
                                <p className="mt-2 leading-7">
                                  As permissões concedidas para a integração incluem:
                                </p>
                                <ul>
                                  <li>Acesso à sua agenda: Para consultar eventos existentes.</li>
                                  <li>Criação de eventos: Permite que assistentes criem compromissos na sua agenda.</li>
                                  <li>Edição de eventos: Permite modificar detalhes de eventos existentes.</li>
                                  <li>Exclusão de eventos: Permite a remoção de eventos, se necessário.</li>
                                </ul>
                                <h3 className="mt-4 text-xl font-semibold text-gray-900">7.3 Revogação de Permissões</h3>
                                <p className="mt-2 leading-7">
                                  Você pode revogar a autorização do Google Agenda a qualquer momento acessando as configurações de segurança da sua conta Google ou pela prórpia plataforma Verbeux.
                                </p>
                            </section>

                            {/* 8. Limitação de Responsabilidade */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">8. Limitação de Responsabilidade</h2>
                                <p className="mt-4 leading-7">
                                  A Verbeux não se responsabiliza por quaisquer danos diretos, indiretos, incidentais ou consequenciais resultantes do uso ou incapacidade de usar a plataforma.
                                </p>
                                <p>
                                  A integração com serviços de terceiros, como o Google Agenda, é fornecida "no estado em que se encontra" e está sujeita às condições e disponibilidade dos serviços terceiros.
                                </p>
                            </section>

                            {/* 9. Modificações dos Termos */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">9. Modificações dos Termos</h2>
                                <p className="mt-4 leading-7">
                                  A Verbeux reserva-se o direito de modificar estes Termos de Uso a qualquer momento.
                                </p>
                                <p>
                                  Notificações sobre alterações substanciais serão enviadas para o email cadastrado ou exibidas na plataforma.
                                </p>
                            </section>

                            {/* 10. Cancelamento de Conta */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">10. Cancelamento de Conta</h2>
                                <p className="mt-4 leading-7">
                                  Você pode cancelar sua conta a qualquer momento. Após o cancelamento, suas informações podem ser retidas conforme necessário para cumprir obrigações legais ou contratuais.
                                </p>
                            </section>

                            {/* 11. Contato */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">11. Contato</h2>
                                <p className="mt-4 leading-7">
                                    Caso tenha dúvidas, entre em contato conosco:
                                </p>
                                <p className="mt-4 leading-7">
                                    Email:{" "}
                                    <a href="mailto:contato@verbeux.com.br" className="text-purple-600 underline">
                                        contato@verbeux.com.br
                                    </a>
                                </p>
                            </section>

                            {/* 12. Legislação Aplicável */}
                            <section>
                                <h2 className="text-2xl font-semibold text-gray-900">12. Legislação Aplicável</h2>
                                <p className="mt-4 leading-7">
                                    Estes Termos de Uso são regidos pelas leis do Brasil. Data de Atualização: Novembro de 2024
                                </p>
                            </section>
                        </div>
                    </div>
                </main>

                {/* Rodapé */}
                <footer className="bg-white">
                    <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                        <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
                        <div className="sm:flex sm:items-center sm:justify-between">
                          <img className="h-8 w-auto" src={Logo} alt="Verbeux logo"/>
                            <span className="text-sm text-gray-500 sm:text-center">
                               © 2024 Verbeux™. Todos os direitos reservados.
                            </span>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}
